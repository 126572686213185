import React, {useState} from "react";
import styles from "./Need2FA.module.scss";
import {useForm} from "react-hook-form";
import BackArrow from "../../assets/svg/BackArrow";
import Btn from "../../components/Button/Button";
import {useSelector, useDispatch} from "react-redux";
import {set2FAStep} from "../../store/actions";
import {motion} from "framer-motion";
import {setup2FA, login} from "../../store/actions";
import ReactCodeInput from "react-verification-code-input";
import {useHistory} from "react-router-dom";

const pageVariants = {
    initial: {
        opacity: 0,
        y: -500,
        transition: {
            ease: "linear",
            duration: 0.3,
            delay: 0,
        },
    },
    in: {
        opacity: 1,
        y: 0,
        transition: {
            ease: "easeOut",
            duration: 0.5,
        },
    },
    out: {
        transition: {
            ease: "easeInOut",
        },
    },
};

const Need2FA = (props) => {

    const history = useHistory();
    const recaptchaRef = React.useRef();
    const dispatch = useDispatch();
    const {handleSubmit} = useForm();
    const [event, setEvent] = useState("SEND CODE");
    const [twoFACode, setTwoFACode] = useState(0);
    const state = useSelector((state) => state.general);

    const onSubmit = (data) => {
        let formData = new FormData();
        formData.append("code_2fa", data);
        recaptchaRef.current.__clearvalues__();
        setEvent("LOADING");
        if (state.cameFrom === "login") {
            formData.append("username", state.loggedUser.username);
            formData.append("password", state.loggedUser.password);
            dispatch(login(formData, setEvent, state.redirect));
        }
        
        if (state.cameFrom === "setup2fa") {
            formData.append("token", state.loggedUser.token_2fa);
            dispatch(setup2FA(formData, setEvent, "login", history, state));
        }
        
    };

    const back = () => {
        props.restartEvent();
        dispatch(set2FAStep("login"));
    };

    return (
        <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            className={styles.container}
        >
            <div className={styles.formContainer}>
                <div onClick={() => back()} className={styles.back}>
                    <BackArrow />
                </div>
                <h1>Two Factor Authentication</h1>
                <h3>Enter the code</h3>
                <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>          
                    <ReactCodeInput
                        ref={recaptchaRef}
                        onChange={value => setTwoFACode(value)}
                        onComplete={(data)=> handleSubmit(onSubmit(data))}
                    />
                    <Btn 
                        stylebutton={state.source === "trials" ? "trials" : "platform"}
                        type="submit"
                        title="Send code" 
                        event={
                            (twoFACode.length === 6) ? event : "DISABLED"
                        } />
                </form>
            </div>
        </motion.div>
    );
};

export default Need2FA;