import React, {useState, useEffect} from "react";
import {useForm} from "react-hook-form";
import styles from "./FormStyle.module.scss";
import Btn from "../Button/Button";
import {useSelector, useDispatch} from "react-redux";
import {closeSnackbar, setNewPassword} from "../../store/actions";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import {makeStyles} from "@material-ui/core/styles";
import Icon from "../Icon/icon";
import {useHistory} from "react-router-dom";

import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles(() => ({
    formControl: {
        "& > *": {
            marginBottom: "16px"
        },
    },
    cssLabel: {
        "&.Mui-focused": {
            color: "#00758c",
        }
    },
    cssInput: {
        "&$focused $notchedOutline": {
            border: "1px solid #00758c"
        },
    },
    focused: {},
    notchedOutline: {},
}));

const ChangePassword = () => {
    const history = useHistory();
    const classes = useStyles();
    const {handleSubmit} = useForm();
    const [event, setEvent] = useState("CHANGE PASSWORD");

    const [validLength, setValidLength] = useState(false);
    const [validUppercase, setValidUppercase] = useState(false);
    const [validSpecial, setValidSpecial] = useState(false);
    const [validNumber, setValidNumber] = useState(false);
    const [passwordWord, setPasswordWord] = useState("");
    const [passwordWord2, passwordWord2Set] = useState("");
    const [areEquals, setAreEquals] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

    const state = useSelector((state) => state.general);
    const dispatch = useDispatch();

    useEffect(() => {
        comparisonPassword();
    }, [passwordWord, passwordWord2]);

    const comparisonPassword = () => {
        if (passwordWord != "" || passwordWord2 != "") {
            if(passwordWord === passwordWord2) {
                setAreEquals(true);
            } else {
                setAreEquals(false);
            }
        }
    };

    const onSubmit = () => {
        let formData = new FormData();
        formData.append("c", state.tokenResetPassword);
        formData.append("new_password_confirm", passwordWord);
        formData.append("np", passwordWord);
        setEvent("LOADING");
        dispatch(setNewPassword(formData, setEvent, history));

    };

    const checkValidation = pass => {
        const REGEX = {
            LENGTH: /^.{8,}$/,
            UPPERCASE: /(?=.*?[A-Z])(?=.*?[a-z])/,
            SPECIAL_CHAR: /(?=.*?[^a-zA-Z\d\s])/,
            NUMBER: /(?=.*?[0-9])/,
        };
        setValidLength(REGEX.LENGTH.test(pass));
        setValidUppercase(REGEX.UPPERCASE.test(pass));
        setValidSpecial(REGEX.SPECIAL_CHAR.test(pass));
        setValidNumber(REGEX.NUMBER.test(pass));
        setPasswordWord(pass);
    };

    const validation = (password) => {
        return checkValidation(password ? password : "");
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickShowPasswordConfirm = () => {
        setShowPasswordConfirm(!showPasswordConfirm);
    };

    const restartEvent = () => {
        setEvent("CHANGE PASSWORD");
        dispatch(closeSnackbar());
    };

    const passIcon = <Icon uid="check" className={styles.success} />;
    const failIcon = <Icon uid="clear" className={styles.danger} />;

    return (
        <div className={styles.container}>
            <div className={styles.formContainer}>
                <h1>Reset password </h1>
                <h3>Enter your new password below</h3>
                <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>          
                    <FormControl className={classes.formControl} fullWidth variant="outlined">
                        <TextField
                            id="New Password"
                            type={showPassword ? "text" : "password"}
                            variant="outlined"
                            label="New Password"
                            onChange={(e)=>validation(e.target.value)}
                            onInput={() => event === "ERROR" && restartEvent()}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment>
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={()=>handleClickShowPassword()}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                classes: {
                                    root: classes.cssInput,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline,
                                }
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.cssLabel
                                }
                            }}
                        />
                    </FormControl>

                    <ul className={styles.passwordValidationContainer}>
                        <li className={`item-li ${validation.length ? "valid" : ""}`}>
                            {validLength ? passIcon : failIcon}A length of 8 characters
                        </li>
                        <li className={`item-li ${validation.length ? "valid" : ""}`}>
                            {validUppercase ? passIcon : failIcon}1 Uppercase character and 1 Lowercase character
                        </li>
                        <li className={`item-li ${validation.length ? "valid" : ""}`}>
                            {validSpecial ? passIcon : failIcon}1 Special character (e.g # ? ! +)
                        </li>
                        <li className={`item-li ${validation.length ? "valid" : ""}`}>
                            {validNumber ? passIcon : failIcon}1 Number
                        </li>
                    </ul>

                    <FormControl className={classes.formControl} fullWidth variant="outlined">
                        <TextField
                            id="Confirm New Password"
                            type={showPasswordConfirm ? "text" : "password"}
                            variant="outlined"
                            label="Confirm New Password"
                            onChange={(e)=>passwordWord2Set(e.target.value)}
                            onInput={() => event === "ERROR" && restartEvent()}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment>
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={()=>handleClickShowPasswordConfirm()}
                                        >
                                            {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                classes: {
                                    root: classes.cssInput,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline,
                                }
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.cssLabel
                                }
                            }}
                        />
                    </FormControl>

                    <ul className={styles.passwordValidationContainer}>
                        <li className={`item-li ${validation.length ? "valid" : ""}`}>
                            {areEquals ? passIcon : failIcon}Password Confirmed
                        </li>
                    </ul>
                    <Btn 
                        stylebutton={state.source === "trials" ? "trials" : "platform"}
                        type="submit"
                        title="Change Password" 
                        event={
                            (validLength && 
                            validUppercase && 
                            validSpecial && 
                            validNumber && 
                            areEquals) ? event : "DISABLED"
                        } />
                </form>
            </div>
        </div>
    );
};

export default ChangePassword;