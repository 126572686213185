import React from "react";
import styles from "./About.module.scss";
import ProfileSidebar from "../../components/ProfileSidebar/ProfileSidebar";
import {motion} from "framer-motion";
import {useSelector} from "react-redux";
import { treatVersioning } from "../../utils/common_functions";

const pageVariants = {
    initial: {
        opacity: 0,
        y: 500,
        transition: {
            ease: "linear",
            duration: 0.3,
            delay: 0,
        },
    },
    in: {
        opacity: 1,
        y: 0,
        transition: {
            ease: "easeOut",
            duration: 0.5,
        },
    },
    out: {
        x: "100vw",
        transition: {
            ease: "easeInOut",
        },
    },
};

const About = () => {
    const state = useSelector((state) => state.general);
    const [platformVersion, platformVersionSet] = React.useState("");

    React.useEffect(() => {
        if(state.loggedUser.release_version) {
            if(state.loggedUser.release_version.includes(".")) {
                const treatedVersion = treatVersioning(state.loggedUser.release_version);
                platformVersionSet(treatedVersion);
            } else {
                platformVersionSet(state.loggedUser.release_version);
            }        
        }
    }, [state.loggedUser.release_version]);

    return (
        <div className={styles.container}>
            <ProfileSidebar active="About"/>
            {
                (state.loggedUser.release_version) && (
                    <motion.div
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={pageVariants}
                        className={styles.sideContainer}
                    >
                        <div className={styles.aboutContainer}>
                            <h1>About</h1>
                            <div className={styles.creditsItems}>
                                <p>QMENTA Platform Version: <b>{platformVersion}</b></p>
                            </div>
                        </div>
                    </motion.div>
                )
            }

        </div>
    );
};

export default About;