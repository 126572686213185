import * as actionTypes from "./types";

import { axiosInstance, environment } from "../../utils/util.axios";

import axios from "axios";

const options = {
    headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json, text/plain, */*",
        "mint-api-call": 1
    }
};

// ---------------- CHECK AUTH ------------------------------

export const setLoading = (value) => {
    return {
        type: actionTypes.LOADING,
        payload: value,
    };
};


// ---------------- SERVER ERROR ------------------------------

const handleServerError = (dispatch) => {
    const message = "Action failed. Please contact support@qmenta.com";
    
    dispatch(enqueueSnackbar({
        message: message,
        options: {
            key: new Date().getTime() + Math.random(),
            variant: "error"
        },
    }));
};


// ---------------- LOGIN ------------------------------

export const login = (data, setEvent, redirectTo) => async (dispatch) => {
    dispatch(setLoading(true));
    axiosInstance
        .post(`${process.env.REACT_APP_BACKEND_URL}/login`, data, { withCredentials: true })
        .then((response) => {
            let session = response.data;
            dispatch({
                type: actionTypes.SET_PROFILE_LOGIN,
                username: data.get("username"),
                password: data.get("password")
            });

            if (session.success === 1) {
                dispatch(enqueueSnackbar({
                    message: "Successful Login",
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: "success"
                    },
                }));
                setEvent("SUCCESS");
                localStorage.setItem("session_active", session.token);
                localStorage.setItem("qmenta-global-state", JSON.stringify(session));
                window.location.replace(redirectTo);
            } else {
                // No success but no error, only need to complete 2fa step
                if (session.account_state === "2fa_need") {
                    setEvent("SUCCESS");
                    dispatch(enqueueSnackbar({
                        message: session.error,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: "success"
                        },
                    }));
                    dispatch({
                        type: actionTypes.TO_2FA_FROM,
                        payload: "login"
                    });
                    dispatch(set2FAStep("2fa_need"));
                } else {
                    // Error
                    setEvent("ERROR");
                    dispatch(enqueueSnackbar({
                        message: session.error,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: "error"
                        },
                    }));
                    if (session.account_state === "2fa_setup") {
                        dispatch(set2FAToken(session.token_2fa));
                        dispatch(set2FAStep("2fa_setup"));
                    }
                    if (session.account_state === "terms_and_conditions_not_agreed") {
                        dispatch(showTermsAndConditions(true));
                        setEvent("LOGIN");
                    }

                }
            }
        })
        .catch((error) => {
            dispatch(setLoading(false));
            setEvent("ERROR");
            handleServerError(dispatch);
            throw error;
        });
};


// ---------------- SETUP 2FA ------------------------------

export const setup2FA = (data, setEvent, environment, history, state) => async (dispatch) => {
    dispatch(setLoading(true));
    axiosInstance
        .post("/setup2fa", data, {
            withCredentials: true,
            headers: { "Content-Type": "application/json" }
        })
        .then((response) => {
            let session = response.data;
            dispatch(setLoading(false));
            if (session.success === 1) {
                dispatch(enqueueSnackbar({
                    message: session.error,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: "success"
                    },
                }));
                setEvent("SUCCESS");

                if (environment === "profile") {
                    dispatch(getProfile());
                    history.push("/profile");
                }

                if (session.account_state === "2fa_valid") {

                    let formData = new FormData();
                    formData.append("username", state.loggedUser.username);
                    formData.append("password", state.loggedUser.password);
                    formData.append("token_2fa", session.token_2fa);
                    setEvent("SUCCESS");

                    if (environment !== "profile") {
                        dispatch(login(formData, setEvent, state.redirect));
                    }
                }

            } else {
                console.log("FIX STATUS CODE RESPONSE ON THE BACKEND");
                setEvent("ERROR");
                dispatch(enqueueSnackbar({
                    message: session.error,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: "error"
                    },
                }));

                if (session.account_state === "locked") {
                    // Logout the user if the account is locked
                    dispatch(logout());
                }

                if (session.account_state === "2fa_error") {
                    return setEvent("SAVE CHANGES");
                }

                if (session.account_state === "2fa_need") {
                    if (environment === "profile") {
                        dispatch(set2FAStepProfile("2fa_need"));
                    } else {
                        dispatch({
                            type: actionTypes.TO_2FA_FROM,
                            payload: "setup2fa"
                        });
                        dispatch(set2FAStep("2fa_need"));
                    }
                    dispatch(set2FAToken(session.token_2fa));
                    setEvent("SEND CODE");
                }
            }
        })
        .catch((error) => {
            setEvent("ERROR");
            handleServerError(dispatch);
            throw error;
        });
};

// ---------------- RECOVER PASSWORD ------------------------------

export const startRecoverPassword = (data, setEvent, setRecoverForm) => async (dispatch) => {
    setEvent("LOADING");
    axiosInstance
        .post("/forgot_password", data)
        .then((response) => {
            let session = response.data;
            if (session.success === 1) {
                console.log("change response.data.error for the message in the success");
                dispatch(enqueueSnackbar({
                    message: session.message,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: "success"
                    },
                }));
                setEvent("SUCCESS");
                setRecoverForm(false);
            } else {
                console.log("FIX STATUS CODE RESPONSE ON THE BACKEND");
                setEvent("ERROR");
                dispatch(enqueueSnackbar({
                    message: session.error,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: "error"
                    },
                }));
            }
        })
        .catch((error) => {
            console.log({ error });
            setEvent("ERROR");
            handleServerError(dispatch);
        });
};


// ---------------- SETUP NEW PASSWORD ------------------------------

export const setNewPassword = (data, setEvent, history) => async (dispatch) => {
    dispatch(setLoading(true));
    axiosInstance
        .post("/change_password", data)
        .then((response) => {
            let session = response.data;
            if (session.success === 1) {
                dispatch(enqueueSnackbar({
                    message: session.message,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: "success"
                    },
                }));
                setEvent("SUCCESS");
                history.push("/");
            } else {
                console.log("FIX STATUS CODE RESPONSE ON THE BACKEND");
                setEvent("ERROR");
                dispatch(enqueueSnackbar({
                    message: session.error,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: "error"
                    },
                }));
            }
        })
        .catch((error) => {
            console.log({ error });
            setEvent("ERROR");
            handleServerError(dispatch);
        });
};

// ---------------- SETUP NEW PASSWORD FROM PROFILE ------------------------------

export const setNewPasswordFromProfile = (data, setEvent, redirectTo) => async (dispatch) => {
    dispatch(setLoading(true));
    axiosInstance
        .post("/a_user_manager/change_password", data, {
            withCredentials: true,
            headers: { "Content-Type": "application/json" }
        })
        .then((response) => {
            let session = response.data;
            if (session.success === 1) {
                dispatch(enqueueSnackbar({
                    message: session.message,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: "success"
                    },
                }));
                setEvent("SUCCESS");
                window.location.replace(redirectTo);
            } else {
                console.log("FIX STATUS CODE RESPONSE ON THE BACKEND");
                setEvent("ERROR");
                dispatch(enqueueSnackbar({
                    message: session.error,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: "error"
                    },
                }));
                if (session.account_state === "locked") {
                    // Logout the user if the account is locked
                    dispatch(logout());
                }
            }
        })
        .catch((error) => {
            console.log({ error });
            setEvent("ERROR");
            handleServerError(dispatch);
        });
};

// ---------------- REGISTER ------------------------------

export const register = (data, setEvent, history, redirection, errorCounter) => async (dispatch) => {
    dispatch(setLoading(true));
    axios
        .post(`${environment}/registration`, data, options)
        .then((response) => {
            let session = response.data;
            if (session.success === 1) {
                dispatch(enqueueSnackbar({
                    message: session.message,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: "success"
                    },
                }));
                console.log("change the setEvent from ERROR to `SUCCESS` here to prod");
                setEvent("SUCCESS");
                if (redirection === "code") {
                    dispatch(setNewUser({}));
                    history.push("/");
                }
                if (redirection === "bookACall") {
                    window.location.replace("https://neuroimaging.qmenta.com/meetings/landon39/15-min-demo");
                }
            } else {
                if (response.data.error === "The activation code does not exist !") {
                    if (errorCounter === 2) {
                        dispatch(setNewUser({}));
                        window.location.reload();
                    }
                    dispatch(errorCode(true));
                    dispatch(setErrorAttempts(true));
                }
                console.log("FIX STATUS CODE RESPONSE ON THE BACKEND");
                setEvent("ERROR");
                dispatch(enqueueSnackbar({
                    message: session.error,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: "error"
                    },
                }));
                if (redirection === "code") {
                    setEvent("REGISTER WITH CODE");
                }
                if (redirection === "bookACall") {
                    setEvent("BOOK A CALL");
                }

            }
        })
        .catch((error) => {
            console.log({ error });
            setEvent("ERROR");
            handleServerError(dispatch);
        });
};


// ---------------- SAVE URL TO REDIRECT AFTER LOGIN ------------------------------

export const saveUrlFrom = (value) => {

    switch (value) {
    case "platform":
        sessionStorage.setItem("source", "platform");
        return {
            type: actionTypes.SET_SOURCE,
            source: value,
            redirect: process.env.REACT_APP_FRONTEND_URL,
        };
    case "trials":
        sessionStorage.setItem("source", "trials");
        return {
            type: actionTypes.SET_SOURCE,
            source: value,
            redirect: process.env.REACT_APP_TRIALS_URL,
        };
    case "care":
        sessionStorage.setItem("source", "care");
        return {
            type: actionTypes.SET_SOURCE,
            source: value,
            redirect: process.env.REACT_APP_CARE_URL,
        };
    default:
        return {};
    }
};

// ----------------- SET REGISTRATION CODE ----------------------

export const saveRegistrationCode = (value) => {
    return {
        type: actionTypes.SET_REGISTRATION_CODE,
        payload: value,
    };
};

// ---------------- SET TOKEN RESET PASSWORD------------------------------

export const saveTokenResetPassword = (value) => {
    return {
        type: actionTypes.SET_TOKEN_RESET_PASSWORD,
        payload: value,
    };
};

// ---------------- CHECK TOKEN RESET PASSWORD STATUS ------------------------------
export const checkTokenResetPasswordStatus = (value) => async (dispatch) => {

    axiosInstance
        .get("/check_token_reset_password_status?c=" + value, {}, {
            withCredentials: true,
            headers: { "Content-Type": "application/json" }
        })
        .then((response) => {
            let valid_token = response.data.message.valid;
            if (!valid_token) {
                dispatch(enqueueSnackbar({
                    message: "This Token is no longer valid.",
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: "error"
                    },
                }));
                setTimeout(() => { window.location.replace("/"); }, 5000);
            }
        })
        .catch(() => {
            handleServerError(dispatch);
        });

    return {
        type: actionTypes.SET_TOKEN_RESET_PASSWORD,
        payload: value,
    };
};


// ---------------- SET USER ------------------------------

export const setNewUser = (value) => {
    return {
        type: actionTypes.SET_NEW_USER,
        payload: value,
    };
};

// ---------------- SNACKBAR ACTIONS ------------------------------

const enqueueSnackbar = (notification) => {
    const key = notification.options && notification.options.key;

    return {
        type: actionTypes.ENQUEUE_SNACKBAR,
        notification: {
            ...notification,
            key: key || new Date().getTime() + Math.random(),
        },
    };
};

export const closeSnackbar = key => ({
    type: actionTypes.CLOSE_SNACKBAR,
    dismissAll: !key, // dismiss all if no key has been defined
    key,
});

export const removeSnackbar = key => ({
    type: actionTypes.REMOVE_SNACKBAR,
    key,
});


// ---------------- SET COOKIE ------------------------------

export const setCookieModal = () => {
    return {
        type: actionTypes.SET_COOKIE_MODAL
    };
};

// ---------------- SET POSITION_Y ------------------------------

export const showOnScroll = (value) => {
    return {
        type: actionTypes.SET_SHOW_ON_SCROLL,
        payload: value
    };
};

// ---------------- CHECK USER FOR PROFILE ------------------------------

export const getProfile = () => async (dispatch) => {
    axiosInstance
        .post("/me", {}, {
            withCredentials: true,
            headers: { "Content-Type": "application/json" }
        })
        .then((response) => {
            let session = response.data;
            dispatch({
                type: actionTypes.SET_PROFILE,
                payload: session.user_data
            });
        })
        .catch((error) => {
            console.log({ error });
            handleServerError(dispatch);
        });
};


// ---------------- CHECK IF IT IS AUTHENTICATED ------------------------------

export const setAuthentication = () => async (dispatch) => {
    axiosInstance
        .post(`${process.env.REACT_APP_BACKEND_URL}/me`, {}, {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(async (response) => {
            const session = response.data;
            if (response.status !== 200 || session.success !== 1) {
                localStorage.removeItem("session_active");
                dispatch({
                    type: actionTypes.SET_SESSION,
                    payload: false
                });
            } else {
                if (session.success === 1) {
                    dispatch({
                        type: actionTypes.SET_SESSION,
                        payload: [
                            session.user_data,
                            true
                        ]
                    });
                }
            }
        })
        .catch((error) => {
            throw error;
        });
};

// ---------------- UPDATE USER FOR PROFILE ------------------------------

export const updateUser = (data, setEvent) => async (dispatch) => {
    axiosInstance
        .post("/a_user_manager/change_user_data", data, {
            withCredentials: true,
            headers: { "Content-Type": "application/json" }
        })
        .then((response) => {
            const session = response.data;
            if (session.success === 1) {
                dispatch(enqueueSnackbar({
                    message: "Update Data Success",
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: "success"
                    },
                }));
                setEvent("SUCCESS");
            } else {
                console.log("FIX STATUS CODE RESPONSE ON THE BACKEND");
                setEvent("ERROR");
                dispatch(enqueueSnackbar({
                    message: session.error,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: "error"
                    },
                }));
            }
        })
        .catch(() => {
            setEvent("ERROR");
            handleServerError(dispatch);
        });
};

// ---------------- SHOW TERMS AND CONDITIONS ------------------

export const showTermsAndConditions = (value) => async (dispatch) => {
    dispatch({
        type: actionTypes.SET_TERMS,
        payload: value
    });
};

// ---------------- OPEN 2FA STEP ------------------------------

export const set2FAStep = (value) => async (dispatch) => {
    dispatch({
        type: actionTypes.SET_2FA_STEP,
        payload: value
    });
};

// ---------------- 2FA STEP PROFILE------------------------------

export const set2FAStepProfile = (value) => async (dispatch) => {
    dispatch({
        type: actionTypes.SET_2FA_STEP_PROFILE,
        payload: value
    });
};


// ---------------- SET 2FA TOKEN------------------------------

export const set2FAToken = (value) => async (dispatch) => {

    dispatch({
        type: actionTypes.SET_2FA_TOKEN,
        payload: value
    });
};

// ---------------- ERROR CODE ATTEMPTS------------------------------

export const setErrorAttempts = () => {
    return {
        type: actionTypes.SET_ERROR_ATTEMPTS,
    };
};

// ---------------- ERROR CODE ------------------------------

export const errorCode = (value) => {
    return {
        type: actionTypes.SET_ERROR_CODE,
        payload: value
    };
};

// ---------------- LOGOUT ------------------------------

export const logout = () => async (dispatch) => {
    axiosInstance
        .post("/logout", {}, {
            withCredentials: true,
            headers: { "Content-Type": "application/json" }
        })
        .then((response) => {
            let session = response.data;
            if (session.success === 1) {
                localStorage.removeItem("session_active");
                localStorage.removeItem("token");
                localStorage.removeItem("qmenta-global-state");
                window.location.replace("/");
            } else {
                //  console.log("FIX STATUS CODE RESPONSE ON THE BACKEND");
                dispatch(enqueueSnackbar({
                    message: session.error,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: "error"
                    },
                }));
            }
        })
        .catch(() => {
            // setEvent("ERROR");
            handleServerError(dispatch);
        });
};