import {Redirect, Route, Switch} from "react-router-dom";

import About from "../../containers/About/About";
import ChangePasswordProfile from "../../containers/ChangePasswordProfile/ChangePasswordProfile";
import Credits from "../../containers/Credits/Credits";
import Data from "../../containers/Data/Data";
import ProfileHeader from "../ProfileHeader/ProfileHeader";
import React from "react";
import TwoFactor from "../../containers/TwoFactor/TwoFactor";
import {logout} from "../../store/actions";
import {useDispatch} from "react-redux";

const Profile = (props) => {

    let url = props.match.url;
    const dispatch = useDispatch();

    return (
        <div>
            <ProfileHeader/>
            <div>
                <Switch>
                    <Route path={url + "/logout"} render={() => {
                        dispatch(logout());
                    }} />
                    <Route path={url + "/data"} component={Data}  />
                    <Route path={url + "/change-password"} component={ChangePasswordProfile}/>
                    <Route path={url + "/factor-authenticator"} component={TwoFactor}/>
                    <Route path={url + "/credits"} component={Credits}/>
                    <Route path={url + "/about"} component={About}/>
                    <Redirect from="/" to={url + "/data"}/>
                </Switch>
            </div>
        </div>

    );
};

export default Profile;
